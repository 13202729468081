import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Menu, Spinner, Text } from 'grommet';
import { Checkmark, Money, Notification } from 'grommet-icons';
import './style.css';
import { navigate } from '@reach/router';
import { navigate as gNavigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { rootConfig } from '../../config/root-config';
import { selectUnreadNotices } from '../../state/modules/data';
import retrieveNoticesThunk from '../../state/modules/data/thunks/retrieve-notices.thunk';
import { appColors } from '../../utils/colors';
import switchUserAccountThunk from '../../state/modules/data/thunks/switch-user-account.thunk';

const StyledUser = styled(Box)`
  color: ${() => appColors.brand};
  background-color: ${() => appColors.white};
  border-radius: 100%;
  min-height: 28px !important;
  min-width: 28px !important;
  font-weight: 600;
`;

const menuButtonSharedOptions = {
  style: {
    // paddingBottom: '8px', paddingTop: '8px'
  },
};

const MenuItem = ({ text, Icon, loading, color }: any) => (
  <Box direction="row" gap="small" pad={{ right: 'small' }} align="center">
    <Box width="20px">
      {!loading ? !!Icon && <Icon /> : <Spinner size="xsmall" color="white" />}
    </Box>
    <Box>
      <Text size="small" color={color}>
        {text}
      </Text>
    </Box>
  </Box>
);

const ArrowDown = styled<any>(Box)`
  transform: rotate(90deg) translateY(-4.5px);
  max-width: 15px;
  max-height: 15px;
`;

export const MenuUser = ({ label, action, user, account }: any) => {
  const unreadNotices: any = useSelector(state => selectUnreadNotices(state));
  const noticesInterval: any = useRef();
  const dispatch: any = useDispatch();
  const [accountLoading, set_accountLoading] = useState(false);

  const retrieveUnreadNotices = useCallback(
    async ({ body }) => {
      await dispatch(
        retrieveNoticesThunk({
          body,
          withDispatch: true,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    const unreadNoticesPayload = {
      body: {
        pageSize: 1,
        page: 1,
        filters: {
          and: [
            {
              field: 'opened',
              value: true,
              operator: 'and',
              type: 'not',
            },
            {
              field: 'type',
              value: `irnerio:%`,
              operator: 'and',
              type: 'like',
            },
          ],
        },
      },
    };

    retrieveUnreadNotices(unreadNoticesPayload).then(() => null);

    if (noticesInterval.current) {
      clearInterval(noticesInterval.current);
    }

    if (!rootConfig.isDebug) {
      noticesInterval.current = setInterval(() => {
        retrieveUnreadNotices(unreadNoticesPayload).then(() => null);
      }, 30000);
    }
    return () => {
      if (noticesInterval.current) {
        clearInterval(noticesInterval.current);
      }
    };
  }, [dispatch, retrieveUnreadNotices]);

  const openUser = useCallback(() => {
    navigate(`${rootConfig.app_path}/account/utenti-edit/${user?.id}`).then(
      () => null,
    );
  }, [user?.id]);

  const openCreateAccount = useCallback(() => {
    gNavigate(`/completa/?l=1&email=${user.email}`);
  }, [user.email]);

  const switchUserAccount = useCallback(
    newAccountId => {
      dispatch(
        switchUserAccountThunk({
          body: { userId: user?.id, newAccountId },
          setLoader: set_accountLoading,
        }),
      ).then(result => {
        if (!result.error) {
          // @ts-ignore
          window.location = `${window.location.href}?cache=no`;
        }
      });
    },
    [dispatch, user?.id],
  );

  const openAccount = useCallback(() => {
    navigate(`${rootConfig.app_path}/account/home`).then(() => null);
  }, []);

  const openNotices = useCallback(() => {
    navigate(`${rootConfig.app_path}/drive/notices`).then(() => null);
  }, []);

  return (
    <Box
      justify="center"
      pad={{ bottom: '0px', horizontal: 'small' }}
      height="80%"
      direction="row"
      gap="22px"
      align="center"
    >
      {+user?.credits >= 0 && (
        <Box gap="8px" direction="row" align="end" focusIndicator={false}>
          <Text size="small" weight="bold">
            {+user.credits > 0 ? user.credits : 0}
          </Text>
          <Money />
        </Box>
      )}

      <Box
        direction="row"
        align="end"
        onClick={openNotices}
        focusIndicator={false}
      >
        <Notification color={unreadNotices ? 'brand' : undefined} />
      </Box>

      <Menu
        margin={{ left: '-12px' }}
        dropProps={{
          align: { top: 'bottom', right: 'right' },
          elevation: 'medium',
          margin: { left: '-12px', top: '8px' },
          background: 'brand',
          pad: 'none',
        }}
        label={
          <Box direction="row" align="center" justify="center" gap="xsmall">
            <StyledUser align="center" justify="center">
              {`${user?.first_name?.charAt(0) || ''}${
                user?.first_name
                  ? user?.last_name?.charAt(0) || ''
                  : user?.last_name?.slice(0, 2) || ''
              }
              `.toUpperCase()}
            </StyledUser>
            <ArrowDown justify="center" align="center" focusIndicator={false}>
              <Text size="small" color="white">
                {'>'}
              </Text>
            </ArrowDown>
          </Box>
        }
        icon={false}
        items={[
          [
            ...(user?.Accounts ?? [])?.map(el => ({
              label: (
                <MenuItem
                  text={el?.nome_studio ?? 'Caricamento...'}
                  Icon={el?.id === account?.id && Checkmark}
                  loading={accountLoading}
                />
              ),
              onClick: () => switchUserAccount(el?.id),
              ...menuButtonSharedOptions,
            })),
            {
              label: <MenuItem text="Aggiungi Account" color="headText2" />,
              onClick: openCreateAccount,
              ...menuButtonSharedOptions,
            },
          ],
          [
            {
              label: <MenuItem text="Il mio profilo" />,
              onClick: openUser,
              ...menuButtonSharedOptions,
            },
            {
              label: <MenuItem text="Impostazioni account" />,
              onClick: openAccount,
              ...menuButtonSharedOptions,
            },
            {
              label: <MenuItem text="LOGOUT" />,
              onClick: action,
              ...menuButtonSharedOptions,
              style: { ...menuButtonSharedOptions?.style },
            },
          ],
        ]}
      />
    </Box>
  );
};
